<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-title>
        <span class="text-h6">Importar Nota Fiscal (Modelo 55)</span>
      </v-card-title>

      <v-card-text>
        <!-- Seleção do arquivo XML -->
        <v-file-input
          label="Selecione o arquivo XML"
          accept=".xml"
          v-model="xmlFile"
          outlined
          dense
          @change="handleXmlUpload"
        ></v-file-input>

        <!-- Informações gerais da Nota Fiscal -->
        <v-container v-if="xmlParsed" class="mt-4" fluid>
          <v-row>
            <v-col cols="12" sm="6">
              <strong>Chave de Acesso:</strong> {{ invoice.chave }}
            </v-col>
            <v-col cols="12" sm="6">
              <strong>Número:</strong> {{ invoice.numero }}
            </v-col>
            <v-col cols="12" sm="6">
              <strong>Série:</strong> {{ invoice.serie }}
            </v-col>
            <v-col cols="12" sm="6">
              <strong>Código de Autorização:</strong>
              {{ invoice.codAutorizacao }}
            </v-col>
            <v-col cols="12" sm="6">
              <strong>Data de Emissão:</strong> {{ invoice.dataEmissao }}
            </v-col>
            <v-col cols="12" sm="6">
              <strong>Valor Total:</strong> {{ invoice.valorTotal }}
            </v-col>
            <v-col cols="12" sm="6">
              <strong>Fornecedor:</strong> {{ invoice.emitente.nome }} (CNPJ:
              {{ invoice.emitente.cnpj }})
            </v-col>
            <v-col cols="12" sm="6">
              <strong>Endereço do Fornecedor:</strong>
              {{ invoice.emitente.endereco }}
            </v-col>
            <v-col cols="12">
              <strong>Informações Complementares:</strong>
              {{ invoice.infComplementar }}
            </v-col>
          </v-row>
        </v-container>

        <!-- Tabela de Produtos -->
        <v-data-table
          v-if="xmlParsed"
          :headers="productHeaders"
          :items="invoice.produtos"
          dense
          disable-sort
          class="mt-5"
        >
          <template v-slot:item.exists="{ item }">
            <v-chip
              :color="item.exists ? 'success' : 'error'"
              small
              class="text-white"
            >
              {{ item.exists ? "Sim" : "Não" }}
            </v-chip>
          </template>

          <template v-slot:item.cProd="{ item }">
            {{ item.cProd }}
          </template>
          <template v-slot:item.nome="{ item }">
            {{ item.nome }}
          </template>
          <template v-slot:item.valorUnitario="{ item }">
            {{ item.valorUnitario }}
          </template>
          <template v-slot:item.quantidade="{ item }">
            {{ item.quantidade }}
          </template>
          <template v-slot:item.ncm="{ item }">
            {{ item.ncm }}
          </template>
          <template v-slot:item.cest="{ item }">
            {{ item.cest }}
          </template>
          <template v-slot:item.gtin="{ item }">
            {{ item.gtin }}
          </template>
          <template v-slot:item.cfop="{ item }">
            {{ item.cfop }}
          </template>
          <template v-slot:item.unidadeComercial="{ item }">
            {{ item.unidadeComercial }}
          </template>
          <template v-slot:item.origem="{ item }">
            {{ item.origem }}
          </template>
          <template v-slot:item.icmsCst="{ item }">
            {{ item.icmsCst }}
          </template>
          <template v-slot:item.icmsAliquota="{ item }">
            {{ item.icmsAliquota }}
          </template>
          <template v-slot:item.pisCst="{ item }">
            {{ item.pisCst }}
          </template>
          <template v-slot:item.pisAliquota="{ item }">
            {{ item.pisAliquota }}
          </template>
          <template v-slot:item.cofinsCst="{ item }">
            {{ item.cofinsCst }}
          </template>
          <template v-slot:item.cofinsAliquota="{ item }">
            {{ item.cofinsAliquota }}
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="closeDialog">Fechar</v-btn>
        <v-btn
          color="success"
          dark
          :disabled="!xmlParsed"
          @click="confirmImport"
        >
          Confirmar Importação
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NfeEntryModal",
  data() {
    return {
      dialog: false,
      xmlFile: null,
      xmlParsed: false,
      invoice: {
        chave: "",
        numero: "",
        serie: "",
        codAutorizacao: "",
        dataEmissao: "",
        valorTotal: "",
        infComplementar: "",
        emitente: {
          cnpj: "",
          nome: "",
          endereco: "",
        },
        produtos: [],
      },
      productHeaders: [
        { text: "Cadastrado", value: "exists" },
        { text: "Código", value: "cProd" },
        { text: "Nome", value: "nome" },
        { text: "Valor", value: "valorUnitario" },
        { text: "Quant.", value: "quantidade" },
        { text: "NCM", value: "ncm" },
        { text: "CEST", value: "cest" },
        { text: "GTIN", value: "gtin" },
        { text: "CFOP", value: "cfop" },
        { text: "UN", value: "unidadeComercial" },
        { text: "ICMS CST", value: "icmsCst" },
        { text: "ICMS %", value: "icmsAliquota" },
        { text: "PIS CST", value: "pisCst" },
        { text: "COFINS CST", value: "cofinsCst" },
      ],
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },
    resetForm() {
      this.xmlFile = null;
      this.xmlParsed = false;
      this.invoice = {
        chave: "",
        numero: "",
        serie: "",
        codAutorizacao: "",
        dataEmissao: "",
        valorTotal: "",
        infComplementar: "",
        emitente: {
          cnpj: "",
          nome: "",
          endereco: "",
        },
        produtos: [],
      };
    },
    async handleXmlUpload(file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        await this.parseXmlData(text);
      };
      reader.readAsText(file);
    },
    async parseXmlData(xmlContent) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlContent, "application/xml");

      const infNFe = xmlDoc.getElementsByTagName("infNFe")[0];
      const protNFe = xmlDoc.getElementsByTagName("infProt")[0];

      this.invoice = {
        chave: infNFe.getAttribute("Id"),
        numero: infNFe.getElementsByTagName("nNF")[0]?.textContent,
        serie: infNFe.getElementsByTagName("serie")[0]?.textContent,
        codAutorizacao: protNFe?.getElementsByTagName("nProt")[0]?.textContent,
        dataEmissao: infNFe.getElementsByTagName("dhEmi")[0]?.textContent,
        valorTotal: this.moneyBr(
          xmlDoc.getElementsByTagName("vNF")[0]?.textContent
        ),
        infComplementar: xmlDoc.getElementsByTagName("infCpl")[0]?.textContent || "",
        emitente: {
          cnpj: infNFe.getElementsByTagName("CNPJ")[0]?.textContent,
          nome: infNFe.getElementsByTagName("xNome")[0]?.textContent,
          endereco:{
            rua: infNFe.getElementsByTagName("xLgr")[0]?.textContent,
            numero: infNFe.getElementsByTagName("nro")[0]?.textContent,
            bairro: infNFe.getElementsByTagName("xBairro")[0]?.textContent,
            cidade: infNFe.getElementsByTagName("xMun")[0]?.textContent,
            estado: infNFe.getElementsByTagName("UF")[0]?.textContent,
            cep: infNFe.getElementsByTagName("CEP")[0]?.textContent,
          },
        },
        produtos: await this.parseProducts(xmlDoc),
      };

      this.xmlParsed = true;
    },
    async parseProducts(xmlDoc) {
      const detTags = xmlDoc.getElementsByTagName("det");
      const produtos = [];

      for (let i = 0; i < detTags.length; i++) {
        const prod = detTags[i].getElementsByTagName("prod")[0];
        const imposto = detTags[i].getElementsByTagName("imposto")[0];

        const namespaceResolver = (prefix) => {
          if (prefix === "nfe") return "http://www.portalfiscal.inf.br/nfe";
          return null;
        };

        const cstCofinsNode = xmlDoc.evaluate(
          "//nfe:COFINS/nfe:COFINSOutr/nfe:CST",
          xmlDoc,
          namespaceResolver,
          XPathResult.FIRST_ORDERED_NODE_TYPE,
          null
        ).singleNodeValue;

        const cstPisNode = xmlDoc.evaluate(
          "//nfe:PIS/nfe:PISOutr/nfe:CST",
          xmlDoc,
          namespaceResolver,
          XPathResult.FIRST_ORDERED_NODE_TYPE,
          null
        ).singleNodeValue;

        const cstICMSNode = xmlDoc.evaluate(
          "//nfe:ICMS/nfe:ICMSSN102/nfe:CSOSN",
          xmlDoc,
          namespaceResolver,
          XPathResult.FIRST_ORDERED_NODE_TYPE,
          null
        ).singleNodeValue;

        const ean = prod.getElementsByTagName("cEAN")[0]?.textContent || null;
        const supplierCode =
          prod.getElementsByTagName("cProd")[0]?.textContent || null;

        const exists = await this.checkProductExists(ean, supplierCode);

        produtos.push({
          cProd: prod.getElementsByTagName("cProd")[0]?.textContent,
          nome: prod.getElementsByTagName("xProd")[0]?.textContent,
          valorUnitario: this.moneyBr(
            prod.getElementsByTagName("vUnCom")[0]?.textContent
          ),
          quantidade: parseFloat(
            prod.getElementsByTagName("qCom")[0]?.textContent
          ),
          ncm: prod.getElementsByTagName("NCM")[0]?.textContent,
          cest: prod.getElementsByTagName("CEST")[0]?.textContent || "",
          gtin: prod.getElementsByTagName("cEAN")[0]?.textContent || "SEM GTIN",
          cfop: prod.getElementsByTagName("CFOP")[0]?.textContent,
          unidadeComercial: prod.getElementsByTagName("uCom")[0]?.textContent,
          origem: imposto.getElementsByTagName("orig")[0]?.textContent || "",
          icmsCst: cstICMSNode ? cstICMSNode.textContent : "",
          icmsAliquota:
            imposto?.getElementsByTagName("pICMS")[0]?.textContent || 0.0,
          pisCst: cstPisNode ? cstPisNode.textContent : "",
          pisAliquota:
            imposto?.getElementsByTagName("pPIS")[0]?.textContent || "",
          cofinsCst: cstCofinsNode ? cstCofinsNode.textContent : "",
          cofinsAliquota:
            imposto?.getElementsByTagName("pCOFINS")[0]?.textContent || "",
          exists,
        });
      }

      return produtos;
    },

    checkProductExist(cProd, gtin) {
      const products = this.$store.getters["product/products"];
      const product = products.find((p) => p.code === cProd || p.gtin === gtin);

      return product ? true : false;
    },

    moneyBr(value) {
      if (!value) {
        var value = 0;
      }

      var parsedValue = parseFloat(value);
      var isNegative = parsedValue < 0;
      var value = Math.abs(parsedValue).toFixed(2).split(".");

      value[0] = value[0].split(/(?=(?:...)*$)/).join(".");

      return "R$ " + (isNegative ? "-" : "") + value.join(",");
    },

    async confirmImport() {
      if (!this.xmlParsed) {
        alert("Nenhum XML foi carregado.");
        return;
      }

      try {
        const formData = new FormData();
        formData.append("invoice", JSON.stringify(this.invoice));
        formData.append("xmlFile", this.xmlFile);

        const response = await this.$http.$post("/invoice/import", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        this.closeDialog();
      } catch (error) {
        console.error("Erro ao importar a nota:", error);
      }
    },

    async checkProductExists(ean, supplierCode) {
      try {
        const response = await this.$http.$get("/products/check", {
            ean,
            supplier_code: supplierCode,
          });
        return response.exists; // A API deve retornar um campo `exists` (boolean)
      } catch (error) {
        console.error("Erro ao verificar produto:", error);
        return false;
      }
    },
  },
};
</script>
