<template>
  <v-card flat>
    <v-form v-model="phase" ref="form">
      <v-card-text class="px-0">
        <div :class="dense ? 'row d-flex' : 'col'">
          <div :class="dense ? 'col-md-4' : 'row py-3'">
            <v-text-field
              v-model="form.cnpj"
              v-mask="'##.###.###/####-##'"
              outlined
              label="CNPJ"
              :rules="[rules.required, rules.cnpj]"
              hide-details="auto"
              @blur="checkCNPJ"
              :dense="dense"
            ></v-text-field>
          </div>
          <div :class="dense ? 'col-md' : 'row py-3'">
            <v-text-field
              v-model="form.name"
              outlined
              label="Razão Social"
              :rules="[rules.required]"
              hide-details="auto"
              :dense="dense"
            ></v-text-field>
          </div>
        </div>

        <div :class="dense ? 'row d-flex' : 'col'">
          <div :class="dense ? 'col-md' : 'row py-3'">
            <v-text-field
              v-model="form.inscricao_estadual"
              outlined
              label="Inscrição Estadual"
              hide-details="auto"
              :dense="dense"
            ></v-text-field>
          </div>
            <div :class="dense ? 'col-md' : 'row py-3'">
            <v-text-field
              v-model="form.alias"
              outlined
              label="Nome Fantasia"
              :rules="[rules.required]"
              hide-details="auto"
              :dense="dense"
            ></v-text-field>
          </div>
        </div>

        <div :class="dense ? 'row d-flex' : 'col'" class="mb-2">
          <div :class="dense ? 'col-md-12' : 'row py-3'">
            <v-text-field
              v-model="form.inscricao_municipal"
              outlined
              label="Inscrição Municipal"
              hide-details="auto"
              :dense="dense"
            ></v-text-field>
          </div>
          <div :class="dense ? 'col-md-12' : 'row py-3'">
            <v-checkbox
              v-model="form.inscricao_estadual_isento"
              label="Isento de Inscrição Estadual"
              hide-details="auto"
              :dense="dense"
            ></v-checkbox>
            <v-checkbox v-model="form.nfe_integration" :label="`Irá realizar emissão de notas de produtos no modelo 55`"></v-checkbox>
          </div>
        </div>
        <div v-if="!signup">
          <v-divider></v-divider>
          <v-row class="mt-3">
            <v-card-title class="pb-0">
              <v-icon class="ma-1">{{ icons.mdiPhone }}</v-icon> Contato
            </v-card-title>
          </v-row>
          <v-row class="mb-3">
            <div class="row col-xs">
              <div class="col-md pa-md-3 pb-0">
                <v-text-field
                  outlined
                  label="Telefone"
                  v-model="form.phone"
                  v-mask="'(##) # ####-####'"
                  hide-details="auto"
                  :rules="[rules.phoneNumber]"
                  class="pa-3 pa-md-4"
                  dense
                ></v-text-field>
                <v-text-field
                  outlined
                  label="WhatsApp"
                  hide-details="auto"
                  v-model="form.whatsapp"
                  v-mask="'(##) # ####-####'"
                  :rules="[rules.phoneNumber]"
                  class="pa-3 pa-md-4"
                  dense
                ></v-text-field>
              </div>
              <div class="col-md pa-md-3 pt-0">
                <v-text-field
                  outlined
                  label="E-Mail"
                  hide-details="auto"
                  v-model="form.email"
                  :rules="[rules.email]"
                  class="pa-3 pa-md-4"
                  dense
                ></v-text-field>
                <v-text-field
                  outlined
                  label="Site"
                  hide-details="auto"
                  v-model="form.site"
                  class="pa-3 pa-md-4"
                  dense
                ></v-text-field>
              </div>
            </div>
          </v-row>
        </div>
      </v-card-text>
      <slot></slot>
    </v-form>
  </v-card>
</template>

<script>
import notification from '@/helper/notification'
const axios = require("axios");

import { mdiPhone } from "@mdi/js";

import formMixin from '@/util/mixins/formMixin';

export default {
  props:{
    initialData: {},
    dense: {
      type: Boolean,
      default: false,
    },
    signup: {
      type: Boolean,
      default: false
    }
  },

  mixins: [formMixin],

  data() {
    return {
      icons: {
        mdiPhone,
      },

      phase: null,

      initialDataInput: false,

      dirty: false,

      form: {
        cnpj: null,
        name: null,
        alias: null,
        inscricao_estadual: null,
        inscricao_municipal: null,
        inscricao_estadual_isento: null,
        nfe_integration:null,
        phone: null,
        whatsapp: null,
        email: null,
        site: null
      },

    };
  },

  mounted() {
    if (this.initialData) {
      this.$nextTick(() => {
        this.form = this.initialData;
      })
    }
  },

  updated() {
    this.$emit("submit", this.form);
    this.dirty = true;

    if (!this.initialDataInput) {
      this.dirty = false;
      this.initialDataInput = true;
    }

    if (this.dirty) {
      this.$emit("touched");
    }
  },
  
  methods: {
    checkCNPJ() {

      let parsedcnpj = this.form.cnpj
        .replace(".", "")
        .replace(".", "")
        .replace("/", "")
        .replace("-", "");

      axios
        .get("https://brasilapi.com.br/api/cnpj/v1/" + parsedcnpj)
        .then((response) => {
          if (response.status == 200) {
            this.form = {
              cnpj: this.form.cnpj,
              name: response.data.razao_social,
              alias: response.data.nome_fantasia,
              inscricao_estadual: this.form.inscricaoEstadual,
              inscricao_municipal: this.form.inscricaoMunicipal
            };
          }
        }).catch(error => {
            notification.showError(error);
        });
    },

    validate(){
      return this.$refs.form.validate();
    }
  },
  
};
</script>

<style>
</style>