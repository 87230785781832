var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('h1',{staticClass:"ma-2 ml-0"},[_vm._v("Notas Fiscais de Produtos")]),(_vm.invoices.length)?_c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between py-sm-2 py-6"},[_c('v-row',{attrs:{"align-center":""}},[_c('v-text-field',{staticClass:"user-search text-capitalize col-sm-6 col-10 pa-2",attrs:{"placeholder":"Pesquisar por Número e status","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-tooltip',{staticClass:"col-2",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":"success","content":_vm.invoices.length,"value":_vm.filterApply && _vm.invoices.length > 0,"overlap":""}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.filterApply ? 'success' : 'secondary'},on:{"click":function($event){return _vm.applyFilter()}}},[_vm._v(_vm._s(_vm.icons.mdiFilter))])],1)],1)]}}],null,false,1562081670)},[_c('span',[_vm._v(_vm._s(_vm.filterApply ? "Retirar Filtro" : "Aplicar Filtro"))])])],1),_c('transition',{attrs:{"name":"slide-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedInvoices.length != 0),expression:"selectedInvoices.length != 0"}],staticClass:"my-2",class:{ 'w-full py-2': _vm.isMobile }},[_c('v-btn',{staticClass:"ma-sm-2 my-2 text-capitalize",attrs:{"color":"accent","block":_vm.isMobile},on:{"click":function($event){_vm.reSend(_vm.getIdsOfSelectedsInvoices())}}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(_vm._s(_vm.icons.mdiRestore))]),_c('span',[_vm._v("Reenviar")])],1),_c('v-btn',{staticClass:"ma-sm-2 my-2 text-capitalize",attrs:{"color":"secondary","block":_vm.isMobile},on:{"click":function($event){_vm.consult(_vm.getIdsOfSelectedsInvoices())}}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(_vm._s(_vm.icons.mdiRestore))]),_c('span',[_vm._v("Consultar")])],1)],1)]),_c('div',{class:{ 'w-full py-2': _vm.isMobile }},[_c('v-btn',{staticClass:"my-2 text-capitalize",attrs:{"color":"success","disabled":"","block":_vm.isMobile},on:{"click":function($event){return _vm.newNFE()}}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Nova NF-E")])],1)],1)],1),_c('v-data-table',{attrs:{"items-per-page":25,"page":_vm.page,"show-select":"","search":_vm.searchQuery,"item-key":"id","headers":_vm.tableColumns,"items":_vm.invoices,"loading":_vm.loading,"loading-text":"Carregando Notas...","footer-props":{
        itemsPerPageOptions: [25, 50, 100, -1],
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageAllText: 'Todos',
        disablePagination: true,
        nextIcon: '',
        prevIcon: '',
        pageText: '',
      }},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":_vm.selected},on:{"input":_vm.selectAll}})]},proxy:true},{key:"item.data-table-select",fn:function(ref){
      var item = ref.item;
      var isSelected = ref.isSelected;
      var select = ref.select;
return [_c('v-simple-checkbox',{style:({ opacity: item.status == 'billed' ? 0.2 : 1 }),attrs:{"value":isSelected,"ripple":false,"readonly":item.status == 'billed',"disabled":item.status == 'billed'},on:{"input":function($event){return select($event)}}})]}},{key:"item.customer",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(item.customer.name))]}},{key:"item.chave",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(_vm._f("limiter")(item.chave)))]}},{key:"item.value",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticStyle:{"color":"green"}},[_vm._v(_vm._s(_vm._f("moneyBr")((item.value / 100))))])]}},{key:"item.emited_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(_vm._f("dateBR")(item.emited_at)))]}},{key:"item.link",fn:function(ref){
      var item = ref.item;
return [(item.link)?_c('span',[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v("PDF NFe")])]):_vm._e()]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-semibold text-capitalize",attrs:{"small":"","color":_vm.resolveStatusVariant(item.status)}},[_vm._v(" "+_vm._s(_vm.resolveStatusDescription(item.status))+" ")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.consult(item.chave)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.icons.mdiConsole))]),_c('span',[_vm._v("Consultar")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.openDialogCancel(item.chave)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))]),_c('span',[_vm._v("Cancelar")])],1)],1)],1)],1)]}},{key:"no-results",fn:function(){return [_c('nothing-component')]},proxy:true},{key:"footer",fn:function(){return [_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}],null,false,3295242264),model:{value:(_vm.selectedInvoices),callback:function ($$v) {_vm.selectedInvoices=$$v},expression:"selectedInvoices"}})],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e(),_c('outgoingInvoicesFilterComponent',{ref:"filterForm",on:{"filter":function($event){return _vm.filter($event)}}}),_c('outgoingInvoiceForm',{ref:"form",on:{"onSubmit":function($event){return _vm.setInvoice($event)}}}),_c('json-dialog',{ref:"jsonDialog"}),_c('cancel-product-form',{ref:"cancelForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }