<template>
  <v-app>
    <v-card>
      <v-card-title class="d-flex align-center py-sm-2 py-6">
        <span class="text-h5">Notas Fiscais de Produtos</span>

        <!-- Força o espaço entre o título e o botão -->
        <v-spacer></v-spacer>

        <v-btn
          color="success"
          class="my-2 text-capitalize"
          @click="openXmlImport()"
        >
          <v-icon size="17" class="me-1">{{ icons.mdiPlus }}</v-icon>
          <span>Importar XML</span>
        </v-btn>
      </v-card-title>

      <!-- Tabela de notas existentes -->
      <v-data-table :items="invoices" :headers="tableColumns">
        <template v-slot:item.value="{ item }">
          {{ (item.value / 100) | moneyBr }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            small
            class="font-weight-semibold text-capitalize"
            color="success"
          >
            {{ "processado" | capitalize }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <!-- Outros componentes e dialogs que você já possui -->
    <nothing-component v-if="!invoices.length && !loading" />

    <!-- Dialog ou componente para importação do XML da NF-e -->
    <nfe-entry-form ref="nfeEntry" @xmlImported="onXmlImported" />
  </v-app>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiFilter,
  mdiPlus,
  mdiRestore,
  mdiConsole,
} from "@mdi/js";

import NothingComponent from "@/components/nothingComponent.vue";

// Importar o componente de entrada de NFe
import NfeEntryForm from "@/components/outgoingInvoice/form/NfeEntryForm.vue"; // Exemplo de caminho

export default {
  name: "ProductsInvoicesScreen",

  components: {
    NothingComponent,
    NfeEntryForm,
  },

  data() {
    return {
      loading: false,
      icons: {
        mdiFilter,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiPlus,
        mdiRestore,
        mdiConsole,
      },
      invoices: [],
      tableColumns: [
        { text: "NF-e", value: "number" },
        { text: "Chave", value: "chave" },
        { text: "Serie", value: "serie" },
        { text: "Fornecedor", value: "supplier.name" },
        { text: "Valor", value: "value" },
        { text: "Data de Emissão", value: "emited_at" },
        { text: "Status", value: "status" },
      ],
    };
  },

  async mounted() {
    try {
      const response = await this.$http.index("/nota-fiscal-produto-entrada");
      this.invoices = response.data.data;
    } catch (error) {
      console.error("Erro ao verificar produto:", error);
      return false;
    }
  },

  methods: {
    openXmlImport() {
      this.$refs.nfeEntry.openDialog();
    },
    onXmlImported(newInvoice) {
      // Callback que recebe os dados do componente NfeEntryForm
      // Aqui você pode disparar uma requisição de POST, ou inserir na lista local
      console.log("NF-e importada:", newInvoice);

      // Exemplo: se quiser adicionar à lista local
      this.invoices.push(newInvoice);
    },

    filter(filterData) {
      // ...
    },
  },
};
</script>
