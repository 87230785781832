<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    origin="center center"
    persistent
    :fullscreen="isMobile"
  >
    <v-card>
      <v-card-title> Importar Documento </v-card-title>
      <v-divider></v-divider>
      <v-form v-model="formValid" ref="form" class="pa-6">
        <div class="d-flex justify-content align-center">
          <v-file-input
            label="Selecione o documento..."
            :rules="[ruleRequired]"
            v-model="documentForm.file"
            outlined
            dense
            @change="setFile($event)"
          ></v-file-input>
        </div>
        <div class="row col-xs">
          <div class="col-md-6">
            <v-text-field
              v-model="documentForm.name"
              :rules="[ruleRequired]"
              label="Nome"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </div>
          <div class="col-md-4">
            <v-select
              v-model="documentForm.type"
              :items="type"
              item-value="value"
              item-text="text"
              label="Tipo de documento"
              outlined
              dense
            />
          </div>
          <div class="col-md-2">
            <date-picker
              ref="duePickerRef"
              v-model="documentForm.due_at"
              label="Validade"
            ></date-picker>
          </div>
        </div>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" class="text-capitalize" @click="close()">
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          class="text-capitalize"
          @click="importDocument()"
        >
          <v-icon>
            {{ icons.mdiUpload }}
          </v-icon>
          Importar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiUpload } from "@mdi/js";
import datePicker from "@/components/app/ui/datePicker.vue";
import formMixin from "@/util/mixins/formMixin";
import { format } from "date-fns";

export default {
  name: "DocumentForm",

  components: {
    datePicker,
  },

  mixins: [formMixin],

  data() {
    return {
      dialog: false,
      formValid: null,
      ruleRequired: (v) => !!v || "Campo obrigatório.",

      menus: {
        upload_at: false,
        due_at: false,
      },

      file: null,

      icons: {
        mdiUpload,
      },

      type: [
        { value: "financial", text: "Guias para Pagamento" },
        { value: "others", text: "Documentos gerais" },
        { value: "legal", text: "Documentos legais" },
        { value: "certificate", text: "Certidão negativa" },
      ],

      documentForm: {
        file: null,
        name: null,
        type: null,
        due_at: null,
      },
    };
  },

  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.form.reset();
        this.$refs.duePickerRef.reset();
      }
    },

    documentForm: {
      handler(val) {
        if (val.due_at == null) {
          this.documentForm.due_at = format(new Date(), "yyyy-MM-dd HH:mm:ss");
        }
      },
      deep: true,
    },
  },

  methods: {
    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    setFile(event) {
      this.documentForm.name = event.name.substring(0, event.name.length - 4);
      this.file = event;
    },

    importDocument(event) {
      if (this.$refs.form.validate()) {
        this.loading = true;

        let formData = new FormData();

        formData.append("file", this.file);
        formData.append("type", this.documentForm.type);
        formData.append("name", this.documentForm.name);
        formData.append("due_at", this.documentForm.due_at);

        this.$http
          .$post("/document", formData)
          .then((response) => {
            this.$refs.form.reset();

            this.$emit("updated");

            this.dialog = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
