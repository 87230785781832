<template>
  <v-dialog v-model="dialog" max-width="500px" origin="center center">
    <v-card>
      <v-card-title>
        <span>Filtrar Lançamentos</span>
      </v-card-title>
      <v-card-text>
        <div class="row col-xs">
          <div class="col-md">
            <v-text-field
              v-model="filter.description"
              placeholder="Descrição"
              outlined
              hide-details
              dense
              class="user-search"
            />
          </div>
          <div class="col-md">
            <v-select
              :items="selectStatus"
              v-model="filter.status"
              item-text="text"
              item-value="value"
              label="Status"
              dense
              outlined
              hide-details=""
            ></v-select>
          </div>
        </div>
        <div class="row col-xs">
          <div class="col-md">
            <money-input
              ref="minValueRef"
              v-model="filter.valueMin"
              label="Valor Mínimo"
            ></money-input>
          </div>
          <div class="col-md">
            <money-input
              ref="maxValueRef"
              v-model="filter.valueMax"
              label="Valor Máximo"
              :rules="[rules.greaterThanMin]"
            ></money-input>
          </div>
        </div>
        <div class="row col-xs">
          <div class="col-md">
            <v-combobox
              v-model="filter.accountPlan"
              item-text="description"
              item-value="id"
              label="Plano de Contas"
              dense
              filled
              outlined
              hide-details="auto"
            ></v-combobox>
          </div>
        </div>
        <div class="row col-xs">
          <div class="col-md">
            <date-picker
              ref="createdPickerRef"
              v-model="filter.created_at"
              :range="true"
            ></date-picker>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success">Aplicar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Filter from "@/util/Filters";

import moneyInput from "@/components/app/ui/moneyInput.vue";
import datePicker from "@/components/app/ui/datePicker.vue";
import formMixin from '@/util/mixins/formMixin';

export default {
  components: {
    moneyInput,
    datePicker,
  },

  mixins: [formMixin],

  data() {
    return {
      loading: false,
      dialog: false,
      touched: false,

      filter: {
        description: null,
        account_plan: null,
        valueMin: null,
        valueMax: null,
        status: null,
        ordered_at_min: null,
        ordered_at_max: null,
        is_filter: true,
      },

      date: null,

      accountPlan: [],

      selectStatus: [
        { text: "Processado", value: "processed" },
        { text: "Cancelado", value: "cancelled" },
      ],

      rules: {
        greaterThanMin: (v) =>
          this.filter.valueMin <= this.filter.valueMax ||
          "O valor máximo deve ser maior que o valor minimo.",
      },
    };
  },

  methods: {
    open() {
      (this.filter.description = null),
        (this.filter.account_plan = null),
        (this.filter.valueMin = null),
        (this.filter.valueMax = null),
        (this.filter.status = null),
        (this.filter.ordered_at_min = null),
        (this.filter.ordered_at_max = null),
        (this.touched = false);
      this.is_filter = true;
      this.dialog = true;
      this.getAccountPlan();
    },

    handlingDate(date) {
      if (date == null) {
        this.filter.ordered_at_min = null;
        this.filter.ordered_at_max = null;
      } else {
        this.filter.ordered_at_min = date[0];
        this.filter.ordered_at_max = date[1];
      }
    },

    submitFilter() {
      if (this.filter.valueMin == 0) {
        this.filter.valueMin = null;
      }

      if (this.filter.valueMax == 0) {
        this.filter.valueMax = null;
      }

      this.handlingDate(this.date);

      this.$emit("filter", this.filter);
      this.dialog = false;
    },

    scream(str) {
    },

    getAccountPlan() {
      this.$http
        .$get("/account-plan")
        .then((res) => {
          this.accountPlan = res.data;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style>
</style>