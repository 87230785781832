export default {
  methods: {
    resolveStatusDescription(status) {
      switch (status) {
        case "Draft":
          return "Rascunho";
        case "Pendente":
          return "Pendente";
        case "Enviada":
          return "Enviada";
        case "Em Processamento":
          return "Em Processamento";
        case "Rejeitada":
          return "Rejeitada";
        case "Autorizada":
          return "Autorizada";
        case "Cancelada":
          return "Cancelada";
        case "Inutilizada":
          return "Inutilizada";
        case "Denegada":
          return "Denegada";
        case "Não Autorizada":
          return "Não Autorizada";
        case "Erro":
          return "Erro";
        default:
          return "Não Enviada";
      }
    },

    /**
     * Retorna um ícone e cor correspondente ao status do enum.
     *
     * @param {number} status
     * @returns {object} { icon: string, color: string }
     */
    resolveStatusVariant(status) {
      switch (status) {
        case "Draft":
          return "secondary";
        case "Pendente":
          return "warning";
        case "Enviada":
          return "info";
        case "Em Processamento":
          return "warning";
        case "Rejeitada":
          return "error";
        case "Autorizada":
          return "success";
        case "Cancelada":
          return "error";
        case "Inutilizada":
          return "error";
        case "Denegada":
          return "error";
        case "Não Autorizada":
          return "error";
        case "Erro":
          return "error";
        default:
          return "black";
      }
    },
  },
};
