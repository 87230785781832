<template>
  <div>
    <v-card-text class="d-flex align-center flex-wrap">
      <v-form v-model="fiscalSettingsSide" class="row col-xs">
        <div class="col-md">
          <v-card flat>
            <v-card-title>
              <v-icon class="mr-2">{{ icons.mdiCog }}</v-icon>
              Configurações Fiscais
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-radio-group v-model="settingsForm.ambiente_nf" row>
                    <v-radio label="Produçao" value="1"></v-radio>
                    <v-radio label="Homologação" value="2"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3">
                  <v-select v-model="settingsForm.crt" outlined :items="crt" label="Codigo de Regime Tributario"
                    hide-details="auto" dense>
                  </v-select>
                </v-col>
                <v-col md="3">
                  <v-text-field v-model="settingsForm.aliquota" outlined label="Aliquota" :rules="[rules.required]"
                    hide-details="auto" dense></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field v-model="settingsForm.ccm" outlined label="Inscriçao Municipal (CCM)"
                    :rules="[rules.required]" hide-details="auto" dense></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field v-model="settingsForm.password" outlined label="Senha Prefeitura"
                    :rules="[rules.required]" hide-details="auto" dense></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-text-field v-model="settingsForm.code_service" outlined label="Codigo de Serviço"
                    hide-details="auto" dense></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-text-field v-model="settingsForm.code_tax_city" outlined label="Codigo de tributação da cidade"
                    hide-details="auto" dense></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-text-field v-model="settingsForm.csc" outlined label="CSC"
                    hide-details="auto" dense></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-text-field v-model="settingsForm.csc_id" outlined label="CSC_ID"
                    hide-details="auto" dense></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-text-field v-model="settingsForm.token_emit_nfse" outlined label="Token emissor"
                    hide-details="auto" dense></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field v-model="settingsForm.invoice_last_number" outlined label="Número da última nf-e"
                    :rules="[rules.onlyNumbers]" hide-details="auto" dense></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field v-model="settingsForm.invoice_series" outlined label="Série nf-e"
                    :rules="[rules.onlyNumbers]" hide-details="auto" dense></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- <v-divider></v-divider>
          <v-card flat>
            <v-card-title>
              <v-icon class="ma-2">{{ icons.mdiTextBox }}</v-icon>
              Certificado Digital
            </v-card-title>
            <v-card-text>
              <DigitalCertificateComponent></DigitalCertificateComponent>
            </v-card-text>
          </v-card> -->
        </div>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text class="d-flex justify-md-end justify-center">
      <v-btn color="success" @click="submitForm" class="text-capitalize mr-3">
        <v-icon>
          {{ icons.mdiContentSave }}
        </v-icon>
        Salvar Informações
      </v-btn>
    </v-card-text>
  </div>
</template>

<script>
import DigitalCertificateComponent from "@/components/administration/company/forms/DigitalCertificate.vue";
import { mdiTextBox, mdiCog, mdiContentSave } from "@mdi/js";
import { OPTIONS_CRT } from "@/constants/option";
import formMixin from "@/util/mixins/formMixin";

export default {
  components: {
    DigitalCertificateComponent,
  },

  mixins: [formMixin],

  data() {
    return {
      icons: {
        mdiTextBox,
        mdiCog,
        mdiContentSave,
      },

      crt: OPTIONS_CRT,

      menus: {
        emissao: false,
        vencimento: false,
      },

      certificateSide: null,
      fiscalSettingsSide: null,

      settingsForm: {
        id: null,
        aliquota: null,
        ambiente_nf: null,
        version: null,
        company_name: null,
        cnpj: null,
        cpf: null,
        im: null,
        cmun: null,
        siglaUF: null,
        path_file: null,
        csc: null,
        csc_id: null,
        ccm: null,
        password: null,
        crt: null,
        token_emit_nfse: null,
        code_service: null,
        code_tax_city: null,
        invoice_series: null,
        invoice_last_number: null,
      },

      certificateForm: {
        certificate: null,
        emissao: null,
        vencimento: null,
      },

      dirty: {
        company: false,
        integrations: false,
        signature: false,
        fiscalSettings: false,
      },
    };
  },

  mounted() {
    this.settingsForm = {
      id: this.$store.state.auth.user.company.company_nfe_config.id,
      ambiente_nf: this.$store.state.auth.user.company.company_nfe_config.ambiente_nf,
      version: this.$store.state.auth.user.company.company_nfe_config.version,
      company_name: this.$store.state.auth.user.company.name,
      cnpj: this.$store.state.auth.user.company.cnpj,
      cpf: this.$store.state.auth.user.user.cpf,
      im: this.$store.state.auth.user.company.inscricao_municipal,
      cmun: this.$store.state.auth.user.company.main_address.city_code,
      siglaUF: this.$store.state.auth.user.company.main_address.state,
      path_file: this.$store.state.auth.user.company.company_nfe_config.path_file,
      csc: this.$store.state.auth.user.company.company_nfe_config.csc,
      csc_id: this.$store.state.auth.user.company.company_nfe_config.csc_id,
      ccm: this.$store.state.auth.user.company.company_nfe_config.ccm,
      password: this.$store.state.auth.user.company.company_nfe_config.password,
      crt: this.$store.state.auth.user.company.company_nfe_config.crt,
      aliquota: this.$store.state.auth.user.company.company_nfe_config.aliquota,
      token_emit_nfse: this.$store.state.auth.user.company.company_nfe_config.token_emit_nfse,
      code_service: this.$store.state.auth.user.company.company_nfe_config.code_service,
      code_tax_city: this.$store.state.auth.user.company.company_nfe_config.code_tax_city,
      invoice_series: this.$store.state.auth.user.company.company_nfe_config.invoice_series,
      invoice_last_number: this.$store.state.auth.user.company.company_nfe_config.invoice_last_number,
    };
  },

  methods: {
    submitForm() {
      this.$http
        .$put(`company-settings/${this.settingsForm.id}`, this.settingsForm)
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
