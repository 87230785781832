<template>
    <div class="text-center">
      <v-dialog v-model="dialog" max-width="600">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Cancelar nota fiscal
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-textarea v-model="form.justify" outlined label="Justificativa"></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" @click="dialog = false"> voltar </v-btn>
  
            <v-btn color="error" @click="cancel()"> Cancelar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dialog: false,
        form: {
          chave: null,
          justify: null,
        },
      };
    },
  
    methods: {
      open(item) {
        this.chave = item;
        this.dialog = true;
      },
  
      cancel() {
          this.form.chave = this.chave
  
          this.$http
          .store('/nota-fiscal-produto-cancel', this.form)
          .then(response =>{
              console.log(response)
          })
          .catch(error => {
              console.log(error)
          })
      },
    },
  };
  </script>
  