<template>
  <div>
    <h1 class="ma-2 ml-0">Produtos</h1>
    <v-card v-if="products.length">
      <v-card-title
        class="d-flex align-center justify-space-between py-sm-2 py-6"
      >
        <div class="row align-center">
          <v-text-field
            v-model="searchQuery"
            placeholder="Pesquisar"
            dense
            outlined
            hide-details
            class="user-search text-capitalize col-sm-6 col-10 pa-2"
          ></v-text-field>
          <v-tooltip bottom class="col-2">
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                color="success"
                :content="filteredProducts.length"
                :value="filterApply && filteredProducts.length > 0"
                overlap
              >
                <v-btn
                  icon
                  :disabled="products.length == 0"
                  @click="applyFilter()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon :color="filterApply ? 'success' : 'secondary'">{{
                    icons.mdiFilter
                  }}</v-icon>
                </v-btn>
              </v-badge>
            </template>
            <span>{{ filterApply ? "Retirar Filtro" : "Aplicar Filtro" }}</span>
          </v-tooltip>
        </div>
        <div :class="{ 'w-full py-2': isMobile }">
          <v-btn
            color="success"
            class="ma-sm-2 my-2 text-capitalize"
            :block="isMobile"
            @click="newProduct()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Novo Produto</span>
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="productShow"
        :loading="loading"
        :search="searchQuery"
        loading-text="Carregando Tabela..."
        :footer-props="{
          itemsPerPageOptions: [25, 50, 100, -1],
          itemsPerPageText: 'Itens por pagina',
          itemsPerPageAllText: 'Todos',
          disablePagination: true,
          nextIcon: '',
          prevIcon: '',
          pageText: '',
        }"
        :hide-default-footer="true"
      >
        <template v-slot:item.value="{ item }">
          <span style="color: lightgreen">
            {{ parseCurrency(item.value) }}
          </span>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            <div v-if="item.status === 1">Ativo</div>
            <div v-if="item.status === 0">Inativo</div>
          </v-chip>
        </template>

        <template v-slot:item.product_platform_id="{ item }">
          <span>
            {{ item.product_platform_id | limiter }}
          </span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editProduct(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Editar</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status == 1"
                @click="openAlertConfirmation(item)"
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Desativar</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-else @click="openAlertConfirmation(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiRestore }}
                  </v-icon>
                  <span>Ativar</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <div class="d-flex justify-end">
        <v-pagination
          class="paginate"
          v-model="currentPage"
          :length="lastPage"
          total-visible="6"
        ></v-pagination>
      </div>
    </v-card>
    <nothing-component v-if="!products.length && !loading">
      <div :class="{ 'w-full py-2': isMobile }" class="pa-6">
        <v-btn
          color="success"
          class="ma-sm-2 my-2 text-capitalize"
          :block="isMobile"
          @click="newProduct()"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Novo Produto</span>
        </v-btn>
        <v-btn
          color="secondary"
          class="ma-sm-2 my-2 text-capitalize"
          :block="isMobile"
          @click="exportProducts()"
        >
          <v-icon size="17" class="me-1">
            {{ icons.mdiFileExcel }}
          </v-icon>
          <span>Exportar</span>
        </v-btn>
      </div>
    </nothing-component>
    <div v-if="loading" class="d-flex justify-center">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>

    <ProductForm ref="productForm" @onSubmit="getProducts" />
    <alert
      ref="alert"
      @inactive="updateStatusProduct($event)"
      @active="updateStatusProduct($event)"
    ></alert>
    <products-filter-component
      ref="filterForm"
      @filter="filter($event)"
    ></products-filter-component>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiExportVariant,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiFileExcel,
  mdiFilter,
  mdiRestore,
} from "@mdi/js";
import Filter from "@/util/Filters";

import ProductForm from "@/components/products/forms/ProductForm";
import ProductsFilterComponent from "@/components/app/filters/products/productsFilterComponent.vue";
import NothingComponent from "@/components/nothingComponent.vue";
import Alert from "@/components/app/alert/Alert.vue";

import tableMixin from "@/util/mixins/tableMixin";

const formatador = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
});

export default {
  name: "Products",

  components: { ProductForm, ProductsFilterComponent, NothingComponent, Alert },

  mixins: [tableMixin],

  data() {
    return {
      searchQuery: null,
      icons: {
        mdiPlus,
        mdiExportVariant,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiDeleteOutline,
        mdiFileExcel,
        mdiFilter,
        mdiRestore,
      },
      products: [],
      filteredProducts: [],
      filterApply: false,
      loading: false,
      selectedRows: [],
      currentPage: null,
      lastPage: null,
      tableColumns: [
        { text: "Codigo", value: "sku" },
        { text: "Nome", value: "name" },
        { text: "Plataforma", value: "platform" },
        { text: "Id da Plataforma", value: "product_platform_id" },
        { text: "Valor", value: "value" },
        { text: "Estoque", value: "total_stock" },
        { text: "Status", value: "status", sortable: false },
        {
          text: "Ações",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      searchQuery: null,
    };
  },
  watch: {
    currentPage(newValue) {
      this.getProducts();
    },
  },
  computed: {
    productShow() {
      return this.filterApply ? this.filteredProducts : this.products;
    },
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.loading = true;

      this.$http
        .$get("/product", this.searchQuery)
        .then((res) => {
          this.products = res.data.data;
          this.currentPage = res.data.current_page
          this.lastPage = res.data.last_page
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },

    newProduct() {
      this.$refs.productForm.open();
    },

    openAlertConfirmation(row) {
      this.$refs.alert.open(row);
    },

    editProduct(content) {
      this.$refs.productForm.open(content.id);
    },

    updateStatusProduct(content) {
      this.$http
        .$put(`/product/${content.id}`, content)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    parseCurrency(num) {
      return formatador.format(num / 100);
    },

    applyFilter() {
      if (this.filterApply) {
        this.filterApply = false;
      } else {
        this.$refs.filterForm.open();
      }
    },

    filter(filter) {
      this.searchQuery = filter;
      this.getProducts();
    },
  },
};
</script>

<style></style>
